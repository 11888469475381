/* global config, moment, Promise, angular */

'use strict';

angular.module('managerApp').controller('FraudsController', ($scope, frauds, toast, Auth) => {

    $scope.config = config;
    $scope.hasValidateRefundPermission = Auth.hasPermission('FRAUD', 'PUT') === true;

    $scope.pagination = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: 25,
        totalItems: 0,
        pageChanged: () => {
            getFrauds();
        }
    };

    $scope.deleteFraud = (refund, index) => {
        if (window.confirm('Voulez-vous vraiment supprimer cette déclaration ?')) {
            frauds.delete(refund._id).then(() => {
                $scope.items.splice(index, 1);
                toast.show('La déclaration a bien été supprimée.', 'success');
            }).catch((err) => {
                console.log(err);
                toast.show(err && err.message ? err.message : 'Une erreur est survenue lors du traitement de votre demande.', 'error');
            });
        }
    };

    function getFrauds() {
        let params = {};
        if ($scope.pagination.filters) {
            for (let i in $scope.pagination.filters) {
                let value = $scope.pagination.filters[i];
                if (i === 'date' || i === 'procedureDate') {
                    value = moment(value);
                    if (value.isValid()) {
                        params[i] = value.format(config.date.defaultFormat);
                    }
                } else {
                    if (i === 'procedureType' && value === 'all') {
                    } else {
                        params[i] = value;
                    }
                }
            }
        }
        frauds.get(params, $scope.pagination.currentPage,
                $scope.pagination.itemsPerPage).then((data) => {
            $scope.count = data.count;
            $scope.items = data.items;
            $scope.pagination.totalItems = data.count;
            $scope.$apply();
        }).catch((err) => {
            $scope.error = err;
        });
    }

    getFrauds();

});
